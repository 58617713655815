export default class Tab {
    constructor(target) {
        this.isAttached = false;
        this.target = target;

        this.attach();
    }

    attach() {
        if (this.isAttached) return;

        this.element = document.querySelector(this.target);

        if (this.element) {
            this.attachUI();
            this.isAttached = true;
        }
    }

    attachUI() {
        const tabs = this.element.querySelectorAll(".tab");

        tabs.forEach(tab => {
            tab.addEventListener('click', (event) => {
                const targetTab = event.target;
                const activeTab = this.element.querySelector('.tab.active');
                const activeContent = this.element.querySelector('.tab-panel.active');
                const targetContent = this.element.querySelector(`#${targetTab.getAttribute('data-tab')}`);

                if (targetTab !== activeTab) {
                    activeTab.classList.remove('active');
                    activeContent.classList.remove('active');

                    targetTab.classList.add('active');
                    targetContent.classList.add('active');
                }
            });
        });
    }
}
