export function setError(name, error) {
    var element = document.querySelector(`[name="${name}"]`);
    if(element) {
        const label = element.nextElementSibling;
        element.classList.add('is-invalid');

        if(label) {
            label.textContent = error;
        }
    }
}