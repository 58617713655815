import "../sass/main.scss";

import.meta.glob([
    "../fonts/**",
    "../images/**",
    "../images/*.*",
    "../videos/*.*",
]);
import Prism from "prismjs";

import "prismjs/components/prism-http";
import "prismjs/components/prism-python";
import "prismjs/components/prism-css";
import "prismjs/components/prism-c";
import "prismjs/components/prism-c";
import "prismjs/components/prism-cpp";
import "prismjs/components/prism-csharp";
import "prismjs/components/prism-go";

import "prismjs/themes/prism-tomorrow.css";

import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import "prismjs/plugins/line-numbers/prism-line-numbers";

import { createAvatar } from "@dicebear/core";
import { initials } from "@dicebear/collection";
import { setError } from "../js/utils";
import Validator from "../js/validation";
import Tab from "./tabs";

const csrfToken = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

new Tab(".tab-form");

const menuToggle = document.querySelector(".menu-toggle");
if (menuToggle) {
    const asideMenu = document.querySelector(".management-menu");
    menuToggle.addEventListener("click", (e) => {
        e.preventDefault();

        if (asideMenu.classList.contains("toggle-menu")) {
            asideMenu.classList.remove("toggle-menu");
        } else {
            asideMenu.classList.add("toggle-menu");
        }
    });
}

const listItems = document.querySelectorAll(".list-item");
let activeItem = null;
listItems.forEach((element) => {
    element.addEventListener("click", (e) => {
        // e.preventDefault();
        const item = e.currentTarget;

        const menuLink = item.querySelector("a");
        if (menuLink.classList.contains("active")) {
            return;
        } else {
            if (activeItem) {
                activeItem.classList.remove("active");
            }

            activeItem = menuLink;
            activeItem.classList.add("active");
        }
    });
});

const collapseMenus = document.querySelectorAll(".collapsible-item");
let collapsedMenu = null;
collapseMenus.forEach((element) => {
    element.addEventListener("click", (e) => {
        // e.preventDefault();
        const targetItem = e.currentTarget;
        const target = e.target;

        if (targetItem.classList.contains("collapsed")) {
            if (!target.classList.contains("item")) {
                collapsedMenu = null;
                targetItem.classList.remove("collapsed");
            }
            return;
        } else {
            if (collapsedMenu) {
                collapsedMenu.classList.remove("collapsed");
            }

            collapsedMenu = targetItem;
            collapsedMenu.classList.add("collapsed");
        }
    });
});

const openNav = document.getElementById("openNav");
const closeNav = document.getElementById("closeNav");
const nav_toggle = document.getElementById("nav-mobile-toggle");
const navigation = document.getElementById("navigation");

if (nav_toggle) {
    nav_toggle.addEventListener("click", () => {
        if (navigation.classList.contains("hide")) {
            navigation.classList.remove("hide");
            openNav.setAttribute("aria-hidden", true);
            closeNav.setAttribute("aria-hidden", false);
        } else {
            openNav.setAttribute("aria-hidden", false);
            closeNav.setAttribute("aria-hidden", true);
            navigation.classList.add("hide");
        }
    });
}

// // event bounce on scroll
// window.addEventListener("scroll", bounce_content);
// function bounce_content() {
//     var service = document.querySelectorAll(".service");

//     var windowheight = window.innerHeight;
//     var content_point = 150;
//     // for (var i = 0; i < service.length; i++) {

//     //     var serviceTop = service[i].getBoundingClientRect().top;
//     //     if (serviceTop < windowheight - content_point) {
//     //         service[i].classList.add("bounceup");
//     //     } else {
//     //         service[i].classList.remove("bounceup");
//     //     }
//     // }

//     var willanimate = document.querySelectorAll(".willanimate");

//     for (var i = 0; i < willanimate.length; i++) {
//         var willanimateTop = willanimate[i].getBoundingClientRect().top;
//         if (willanimateTop < windowheight - content_point) {
//             willanimate[i].classList.add("bounceup");
//         } else {
//             willanimate[i].classList.remove("bounceup");
//         }
//     }
// }

window.addEventListener("scroll", debounce(bounce_content, 50)); // Reduced debounce time to 50ms

function bounce_content() {
    const windowheight = window.innerHeight;
    const content_point = 150;

    const willanimate = document.querySelectorAll(".willanimate");

    willanimate.forEach((element) => {
        const willanimateTop = element.getBoundingClientRect().top;
        if (willanimateTop < windowheight - content_point) {
            element.classList.add("bounceup");
        } else {
            element.classList.remove("bounceup");
        }
    });
}

function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

// video speed
/* now play three times as fast just for the heck of it */
var video = document.querySelector("video");
if (video) {
    video.playbackRate = 0.25;
}

// dismiss alert
var alertCloseButton = document.querySelector(".alert-dismiss");

if (alertCloseButton) {
    alertCloseButton.addEventListener("click", (event) => {
        const alert = alertCloseButton.parentElement;
        alert.remove();
        event.preventDefault();
    });
}

// accordions

document.addEventListener("DOMContentLoaded", function () {
    var accordionList = document.querySelectorAll(".accordion-list > li");
    for (var i = 0; i < accordionList.length; i++) {
        var answer = accordionList[i].querySelector(".answer");
        answer.style.display = "none";

        accordionList[i].addEventListener("click", function () {
            var isActive = this.classList.contains("active");

            for (var j = 0; j < accordionList.length; j++) {
                accordionList[j].classList.remove("active");
                accordionList[j].querySelector(".answer").style.display =
                    "none";
            }

            if (!isActive) {
                this.classList.add("active");
                this.querySelector(".answer").style.display = "block";
            }
        });
    }
});

// avatar
const avatars = document.querySelectorAll(".avatar");

avatars.forEach((avatar) => {
    const name = avatar.getAttribute("data-name");
    const svg = createAvatar(initials, {
        // scale: 100,
        // size: 48,
        // radius: 50,
        seed: name,
    });

    avatar.innerHTML = svg;
});

// emails
const contactForm = document.getElementById("contact-form");
var errors = {};

if (contactForm) {
    contactForm.addEventListener("submit", (e) => {
        e.preventDefault();

        // clean the form
        const formElements = document.querySelectorAll(
            "form input, form textarea"
        );
        formElements.forEach((element) => {
            if (element.classList.contains("is-invalid")) {
                element.classList.remove("is-invalid");

                if (element.nextElementSibling.textContent.length > 0) {
                    element.nextElementSibling.textContent = "";
                }
            }
        });

        const form = new FormData(contactForm);

        // fields
        const fullname = form.get("fullname");
        const email = form.get("email");
        const subject = form.get("subject");
        const message = form.get("message");

        const validator = new Validator(
            {
                fullname: fullname,
                email: email,
                subject: subject,
                message: message,
            },
            {
                fullname: "required",
                email: "required|email",
                subject: "required",
                message: "required",
            }
        );

        if (validator.validate()) {
            fetch(contactForm.action, {
                method: "POST",
                headers: {
                    "X-CSRF-TOKEN": csrfToken,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    fullname: fullname,
                    email: email,
                    subject: subject,
                    message: message,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        const alert = document.querySelector(".alert");
                        if (alert) {
                            alert.classList.add("alert-success");
                            const label = alert.querySelector("#message");
                            if (alert) {
                                label.textContent =
                                    "Your message has been successfully sent";
                                alert.style.display = "block";
                            }
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    contactForm.reset();
                });
        } else {
            errors = validator.errors;
            for (var attr in errors) {
                setError(attr, errors[attr]);
            }
        }
    });
}

document.addEventListener("DOMContentLoaded", function () {
    const preTags = document.querySelectorAll("pre");
    preTags.forEach(function (preTag) {
        if (!preTag.classList.contains("language-xxx")) {
            const language = preTag.className.replace("language-", "");
            preTag.classList.add("language-" + language);
        }
    });

    Prism.highlightAll();
});

document.querySelectorAll(".open").forEach(function (openElement) {
    openElement.addEventListener("click", function () {
        var container = openElement.closest(".topic");
        var answer = container.querySelector(".answer");
        var trigger = container.querySelector(".faq-t");

        if (answer.style.display === "none" || !answer.style.display) {
            answer.style.display = "block";
        } else {
            answer.style.display = "none";
        }

        trigger.classList.toggle("faq-o");
        container.classList.toggle("expanded");
    });
});

document.addEventListener("DOMContentLoaded", function () {
    let previousLang = null;

    // Load the selected language from local storage
    const storedLang = localStorage.getItem("selectedLang");
    const storedFlag = localStorage.getItem("selectedFlag");
    if (storedLang && storedFlag) {
        document.querySelector(".current-lang .lang-flag").src = storedFlag;
        document.querySelector(".current-lang .lang-text").innerText =
            storedLang;
    }

    document.querySelectorAll(".selecting-lang").forEach((item) => {
        item.addEventListener("click", (event) => {
            const selectedLang = item.getAttribute("data-lang");
            const selectedFlag = item.getAttribute("data-flag");

            // Show the previously selected language in the dropdown
            if (previousLang) {
                previousLang.style.display = "flex";
            }

            // Update the current language and flag
            document.querySelector(".current-lang .lang-flag").src =
                selectedFlag;
            document.querySelector(".current-lang .lang-text").innerText =
                selectedLang;

            // Hide the selected language in the dropdown and store it as the previous language
            item.style.display = "none";
            previousLang = item;

            // Store the selected language in local storage
            localStorage.setItem("selectedLang", selectedLang);
            localStorage.setItem("selectedFlag", selectedFlag);
        });
    });
});
